/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpSurveyAppStates($stateProvider: any) {
  'ngInject';

  $stateProvider
    .state('auth-survey', {
      parent: 'authenticated',
      redirectTo: '.landing',
      url: '/auth-survey?id&sid',
      templateUrl: 'apps/survey/templates/acp-survey.ng.html',
      params: {
        id: undefined
      },
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(
            /* webpackChunkName: "acp.apps.survey" */ './index'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('survey', {
      parent: 'public',
      url: '/survey?id&sid',
      redirectTo: '.landing',
      templateUrl: 'apps/survey/templates/acp-survey.ng.html',
      params: {
        id: undefined
      },
      data: {
        permissions: {
          except: 'isPasswordAuthed'
        }
      },
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(
            /* webpackChunkName: "acp.apps.survey" */ './index'
          ).then($ocLazyLoad.loadModule);
        },
        $isPasswordAuthed: /*@ngInject */ function (
          nsPermissions,
          $stateParams,
          $state,
          nsStorage
        ) {
          return nsPermissions
            .requestPermission('isPasswordAuthed')
            .then(function (result) {
              if (result) {
                nsStorage.session('survey-params', $stateParams);
                $state.go('auth-survey.landing');
              }
            });
        }
      }
    })
    .state('survey.confirmation', {
      template: `<acp-survey-confirmation-component></acp-survey-confirmation-component>`
    })
    .state('auth-survey.confirmation', {
      template: `<acp-survey-confirmation-component></acp-survey-confirmation-component>`
    })
    .state('survey.landing', {
      template: `<acp-survey-landing-component></acp-survey-landing-component>`
    })
    .state('auth-survey.landing', {
      template: `<acp-survey-landing-component></acp-survey-landing-component>`
    })
    .state('survey.unavailable', {
      templateUrl: 'apps/survey/templates/acp-survey-unavailable.ng.html'
    })
    .state('auth-survey.unavailable', {
      templateUrl: 'apps/survey/templates/acp-survey-unavailable.ng.html'
    })
    .state('survey.invalid_referral_code', {
      templateUrl:
        'apps/survey/templates/acp-survey-invalid-referral-code.ng.html'
    })
    .state('auth-survey.invalid_referral_code', {
      templateUrl:
        'apps/survey/templates/acp-survey-invalid-referral-code.ng.html'
    });
}
